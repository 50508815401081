import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import { PaymentActions } from '../payment/payment.actions';
import { first, of, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { MembershipUpdate } from './membership-update.actions';
import { MembershipUpdateFormStep } from './membership-update.models';
import { getMembershipNumber, MembershipActions } from '@aaa/emember/store-membership';
import { updateTreeValidity } from '../../modules/share/form.utils';
import { MembershipUpdateVm } from '../../modules/membership-update/membership-update.vm';

@Injectable({ providedIn: 'root' })
export class MembershipUpdateEffects {
  actions$ = inject(Actions);
  store = inject(Store);
  vm = inject(MembershipUpdateVm);

  validateFormStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpdate.validateFormStep),
      switchMap(({ validateStep, params }) => {
        const validatePayment = validateStep === MembershipUpdateFormStep.PAYMENT;
        const validateUpdate = validateStep === MembershipUpdateFormStep.UPDATE;

        if (validatePayment) {
          const validatePayment$ = of(null).pipe(
            tap(() => this.store.dispatch(PaymentActions.validate())),
            switchMap(() => this.actions$.pipe(ofType(PaymentActions.validateSucceeded), first())),
            tap(() => this.store.dispatch(PaymentActions.generateToken())),
            switchMap(() => this.actions$.pipe(ofType(PaymentActions.generateTokenSucceeded), first())),
            tap(({ paymentToken }) =>
              this.store.dispatch(
                MembershipUpdate.pay({ params: { ...params, payment: { ...params.payment, token: paymentToken } } }),
              ),
            ),
            switchMap(() => this.actions$.pipe(ofType(MembershipUpdate.paySucceeded), first())),
            map(() => MembershipUpdate.setFormActiveStep({ activeStep: MembershipUpdateFormStep.CONFIRMATION })),
          );

          return validatePayment$;
        }

        if (validateUpdate) {
          const validateAssociate$ = of(null).pipe(
            tap(() => this.store.dispatch(MembershipUpdate.validateAssociate())),
            switchMap(() => this.actions$.pipe(ofType(MembershipUpdate.validateAssociateSucceeded), first())),
            map(() => MembershipUpdate.setFormActiveStep({ activeStep: MembershipUpdateFormStep.PAYMENT })),
          );

          return validateAssociate$;
        }

        return of(MembershipUpdate.setFormActiveStep({ activeStep: validateStep }));
      }),
    ),
  );

  validateAssociate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpdate.validateAssociate),
      map(() => {
        const isInValid = this.vm.formGroup.controls.associates.status !== 'VALID';

        if (isInValid) {
          updateTreeValidity(this.vm.formGroup.controls.associates);

          return MembershipUpdate.validateAssociateFailed();
        }

        return MembershipUpdate.validateAssociateSucceeded();
      }),
    ),
  );

  loadMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipUpdate.paySucceeded),
      withLatestFrom(this.store.select(getMembershipNumber)),
      map(([{ accountDetails }, membershipNumber]) => {
        if (accountDetails) {
          return MembershipActions.updateAccountDetails({ accountDetails });
        }

        return MembershipActions.load({ membershipNumber: String(membershipNumber) });
      }),
    ),
  );
}
